/**
 * Copyright(C), EastMoney.
 * All rights reserved
 *
 * @author wenxiao
 * @description 不要改动
 */


/**  */

import sum from 'lodash/sum'

export const topBarHeight = 56

export const calculate = {
  /**
   * 计算页面的主结构的高度
   * @param {number[]} extraHeights - 需要扣除的相对高度
   * @param {number[]} extraAbsoluteHeights - 需要扣除的绝对高度
   */
  contentMaxHeight: (extraHeights = [], extraAbsoluteHeights = []) =>
    Math.floor((937 - sum(extraHeights)) / 9.37 * (innerHeight / 100)) - topBarHeight - sum(extraAbsoluteHeights)
}

export default topBarHeight
