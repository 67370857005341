/**
 * Copyright(C), EastMoney.
 * All rights reserved
 *
 * @author wenxiao
 * @description 这是一个公共的混入组件，用以剥离 <右侧主区域>.vue 的重复代码。
 */

/** */

import { topBarHeight } from '../config/size'

// export const TBH = topBarHeight

export default {
  data() {
    return {
      topBarHeight
    }
  }
}
